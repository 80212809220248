import { TransformNode, Node } from '@bright-spaces/engine-3d/dist/definitions';
import { Config3D } from "@bright-spaces/engine-3d/dist/helpers";
export const CLIENTS = {
  SKANSKA: "skanska",
  ONE: "one",
  CAIMMO: "caimmo",
  RIVER: "river",
  SKANSKA_FINLAND: "skanska-finland",
  RESOLUTION_PROPERTY: "resolution-property",
  ADVENTUM: "adventum",
  REVETAS: "revetas",
  SHRODERS: "schroders"
}
export const CLIENT_CONFIG_3D = {
  "one": {
    building: [
      Config3D.hdr,
      Config3D.pinManager,
      Config3D.highlightManager,
    ],
    floor: [
      Config3D.loggingEnabled,
      Config3D.hdr,
      Config3D.skybox,
      Config3D.pointerManager,
      Config3D.fitoutsManager,
      Config3D.minimap,
      Config3D.separators,
      Config3D.spaceVisibility,
      Config3D.materialShadows,
      Config3D.fastTravelManager,
      Config3D.lightmapFromAO,
      // Config3D.spaceOrFloor,
    ]
  },
  "skanska": {
    building: [
      Config3D.hdr,
      Config3D.pinManager,
      Config3D.highlightManager,
      Config3D.hidden
    ],
    floor: [
      // Config3D.loggingEnabled,
      Config3D.hdr,
      Config3D.skybox,
      Config3D.pointerManager,
      Config3D.fitoutsManager,
      Config3D.minimap,
      Config3D.separators,
      Config3D.spaceVisibility,
      Config3D.materialShadows,
      Config3D.hidden,
      Config3D.pinManager,
      Config3D.fastTravelManager,
      Config3D.lightmapFromAO,
      // Config3D.spaceOrFloor
    ]
  },
  "cbc": {
    building: [
      Config3D.hdr,
      Config3D.pinManager,
      Config3D.highlightManager,
    ],
    floor: [
      // Config3D.loggingEnabled,
      Config3D.hdr,
      Config3D.skybox,
      Config3D.pointerManager,
      Config3D.fitoutsManager,
      Config3D.minimap,
      Config3D.separators,
      Config3D.spaceVisibility,
      Config3D.materialShadows,
      Config3D.fastTravelManager,
      Config3D.lightmapFromAO,
    ]
  },
  "caimmo": {
    building: [
      Config3D.hdr,
      Config3D.pinManager,
      Config3D.highlightManager,
    ],
    floor: [
      // Config3D.loggingEnabled,
      Config3D.hdr,
      Config3D.skybox,
      Config3D.pointerManager,
      Config3D.fitoutsManager,
      Config3D.minimap,
      Config3D.spaceVisibility,
      Config3D.materialShadows,
      Config3D.fastTravelManager,
      Config3D.lightmapFromAO,
      // Config3D.spaceOrFloor
    ]
  },
  "river": {
    building: [
      Config3D.hdr,
      Config3D.pinManager,
      Config3D.highlightManager,
    ],
    floor: [
      // Config3D.loggingEnabled,
      Config3D.hdr,
      Config3D.skybox,
      Config3D.pointerManager,
      Config3D.fitoutsManager,
      Config3D.minimap,
      Config3D.spaceVisibility,
      Config3D.fastTravelManager,
      Config3D.staticIcons,
      Config3D.lightmapFromAO,
      // Config3D.spaceOrFloor
    ]
  },
  "skanska-finland": {
    building: [
      Config3D.hdr,
      Config3D.pinManager,
      Config3D.highlightManager
    ],
    floor: [
      // Config3D.loggingEnabled,
      Config3D.hdr,
      Config3D.skybox,
      Config3D.pointerManager,
      Config3D.fitoutsManager,
      Config3D.minimap,
      Config3D.pinManager,
      Config3D.fastTravelManager,
      Config3D.lightmapFromAO,
      // Config3D.spaceOrFloor
    ]
  },
  "resolution-property": {
    building: [
      Config3D.hdr,
      Config3D.pinManager,
      Config3D.highlightManager
    ],
    floor: [
      // Config3D.loggingEnabled,
      Config3D.hdr,
      Config3D.skybox,
      Config3D.pointerManager,
      Config3D.fitoutsManager,
      Config3D.minimap,
      Config3D.pinManager,
      Config3D.fastTravelManager,
      Config3D.lightmapFromAO,
      // Config3D.spaceOrFloor
    ]
  },
  "adventum":{
    building: [
      Config3D.hdr,
      Config3D.pinManager,
      Config3D.highlightManager
    ],
    floor: [
      // Config3D.loggingEnabled,
      Config3D.hdr,
      Config3D.skybox,
      Config3D.pointerManager,
      Config3D.fitoutsManager,
      Config3D.minimap,
      Config3D.pinManager,
      Config3D.fastTravelManager,
      Config3D.lightmapFromAO,
      // Config3D.spaceOrFloor
    ]
  },
  "revetas":{
    building: [
      Config3D.hdr,
      Config3D.pinManager,
      Config3D.highlightManager
    ],
    floor: [
      // Config3D.loggingEnabled,
      Config3D.hdr,
      Config3D.skybox,
      Config3D.pointerManager,
      Config3D.fitoutsManager,
      Config3D.minimap,
      Config3D.pinManager,
      Config3D.fastTravelManager,
      Config3D.lightmapFromAO,
      // Config3D.spaceOrFloor
    ]
  },
  "schroders":{
    building: [
      Config3D.hdr,
      Config3D.pinManager,
      Config3D.highlightManager,
      Config3D.buildingOpacityOnHighlight,
    ],
    floor: [
      // Config3D.loggingEnabled,
      Config3D.hdr,
      Config3D.skybox,
      Config3D.pointerManager,
      Config3D.fitoutsManager,
      Config3D.minimap,
      Config3D.pinManager,
      Config3D.fastTravelManager,
      Config3D.lightmapFromAO,
      // Config3D.spaceOrFloor
    ]
  }
}
export class ClientUtils {
  static getSkyboxPath(client: string, options?: {buildingCode?: string, floor?: string}) {
    let path = ""
    switch (client) {
      case CLIENTS.SKANSKA:
        path = "/" + options?.buildingCode + "/" + options?.floor?.replace(/ /g,'').toLowerCase();
        break
      case CLIENTS.RIVER:
        path = "/" + options?.buildingCode + "/" + options?.floor?.replace(/ /g,'').toLowerCase();
        break
      case CLIENTS.SKANSKA_FINLAND:
        path = "/" + options?.floor?.replace(/ /g,'').toLowerCase();
        break
      default:
        break
    }

    return path
  }

  static getSeparatorsNodeName(client: string, options?: {floorCode: string}) {
    let name
    switch (client) {
      case CLIENTS.SKANSKA:
        name = "separators";
        break
      default:
        name = "separators_" + options?.floorCode;
        break
    }

    return name
  }

  static getSpaces(client: string, options: {spaceCode: string, floorCode: string}) {
    const selectedSpaces: string[] = [];
    const selectedSpaceCodes: string[]  = [];
    for (const selectedSpace of options.spaceCode.split(";")) {
      switch (client) {
        case CLIENTS.SKANSKA:
          selectedSpaces.push(selectedSpace + '_' + options.floorCode);
          break;
        case CLIENTS.RIVER:
          selectedSpaces.push("spaces_" + options.floorCode);
          break;
        case CLIENTS.SKANSKA_FINLAND:
          selectedSpaces.push(options.floorCode);
          break;
        default:
          selectedSpaces.push(options.floorCode + '_' + selectedSpace)
          break;
      }
      selectedSpaceCodes.push(selectedSpace);
    }
    return {selectedSpaces, selectedSpaceCodes}
  }

  // this method is used to retrieve fitouts names and unique values
  static getFitoutNames(fitoutsNode: TransformNode, fitoutsInfo: Array<{name: string, ids: string[]}>) {
    if (!fitoutsNode) return []
    return (
      fitoutsNode?.getChildren()?.map((child: Node, idx: number) => {
        let name = `Fitout ${idx + 1}`
        for (const fitoutName of fitoutsInfo) {
          // eslint-disable-next-line array-callback-return
          fitoutName.ids.find((id: string) => {
            if (id === child.name) {
              name = fitoutName.name
            }
          })
        }
        return {
          text: name,
          value: child.id || child.name
        }
      }) || []
    )
  }

  static getLockedFocusNode(spaces: string, floorName: string) {
      const inputSpaces = spaces.split(";");
      let allSpaces = ["s1", "s2", "s3", "s4", "s5"];
      for (const space of inputSpaces) {
        allSpaces = allSpaces.filter((e) => e !== space);
      }
      let finalSpace = "";
      for (const space of allSpaces) {
        finalSpace = finalSpace + "_" + space;
      }
      return "locked_" + floorName + finalSpace;
  }
}
